import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { createTheme, ThemeProvider } from '@mui/material';
import Loader from './loader/Loader';
import * as Sentry from "@sentry/browser";
import App from './app/App';

const theme = createTheme({
    typography: {
        fontFamily: [
            store.getState().app.isIzum ? 'Roboto' : 'Manrope',
            'Arial',
            'sans-serif'
        ].join(',')
    }
});

Sentry.init(
    {
        dsn: 'https://2b09ef303c7644d09cf05ce1e882dcee@glitchtip.mintconf.ru/1'
    }
);
ReactDOM.render(
    <Provider store={store}>
        <React.Suspense fallback={<Loader preloader={true} />}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </React.Suspense>
    </Provider>,
    document.getElementById('root')
);
