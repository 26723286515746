import Loader from "../../loader/Loader"
import Iframe from "../../meet/Iframe"
import { useUsdReadyState } from "../hooks"
import Form from "./Form"
import Header from "./Header"
import Page from "./Page"

const Usd = (props) => {
  const { conferenceServer } = useUsdReadyState()

  if (!conferenceServer) {
    return <Loader preloader={true} />
  }

  /**
   * The match params passed by React Router (App.js)
   */
  const confId = props.match?.params?.confId
  const uslugirtToken = new URLSearchParams(window.location.search).get("one_time_token")

  if (confId && uslugirtToken) {
    return (
      <Iframe
        info={{ uslugirtToken }}
        locationHost={conferenceServer}
        meetId={confId}
      />
    )
  }

  return (
    <Page>
      <Header />
      <Form />
    </Page>
  )
}

export default Usd
