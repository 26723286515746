import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    meetDescriptionChanged,
    meetAdd,
    meetAutoRecording
} from './actions';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Form from './form/Form';
import { useEffect } from 'react';



function Add({ t, isRooms, isWebinars, isCurrents }) {
    const dispatch = useDispatch();
    const { _autoRecording, _user, _isLicenseLoaded, _form } = useSelector(state => {

        return {
            _autoRecording: state.app.settings.autoRecording,
            _user: state.app.user,
            _isLicenseLoaded: state.app.settings.isLicenseLoaded,
            _form: state.meets.form
        }
    })

    function onAddSubmit(event) {
        event.preventDefault();
        dispatch(meetAdd());
    }


    function resetMeetDefaultDescription() {

        if (_form.description === '${default}') {
            if (_autoRecording) {
                dispatch(meetAutoRecording())
            }

            if (isRooms) {
                dispatch(meetDescriptionChanged({ description: t('meetForm.descriptionDefaultValueRoom') }));
            } else {
                dispatch(meetDescriptionChanged({ description: t('meetForm.descriptionDefaultValue') }));
            }
        }
    }
    useEffect(() => {
        resetMeetDefaultDescription();
        // return resetMeetDefaultDescription
    })

    if (!_user.isSecretary || isCurrents)
        return null;

    return (
        <Box className="addMeet">
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="addMeet"
                    id="addMeet"
                >
                    <Typography>
                        {
                            isRooms ?
                                t('addMeet.roomTitle')
                                :
                                t('addMeet.title')
                        }
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Container maxWidth="sm">
                        <Form
                            isRooms={isRooms}
                            isWebinars={isWebinars}
                            optionsExpanded={false}
                            submitBtnText={t('addMeet.create')}
                            onFormSubmit={onAddSubmit}
                        />
                    </Container>

                </AccordionDetails>
            </Accordion>
        </Box>
    )
}




export default withTranslation()(Add);
