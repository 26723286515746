import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  InputBase,
  InputLabel,
  styled,
} from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { useUsdState } from "../hooks"

const FormContainer = styled(Container)(() => ({
  marginTop: "50px",
}))

const Title = styled(CardHeader)(() => ({
  "& .MuiTypography-root": {
    fontSize: "1.1rem",
  }
}))

const Group = styled(Box)(() => ({
  "alignItems": "end",
  "display": "flex",
  "gap": "10px",
  "&> :first-of-type": {
    flexGrow: 1,
  },
}))

const Label = styled(InputLabel)(() => ({
  fontSize: "0.9rem",
}))

const Input = styled(InputBase)(() => ({
  border: "1px solid #dcdcdc",
  borderRadius: "10px",
  padding: "5px 10px",
}))

const JoinButton = styled(Button)(() => ({}))

const HelperText = styled(FormHelperText)(() => ({
  wordBreak: "break-word",
}))

const Agreement = styled(FormControlLabel)(() => ({
  "marginTop": "10px",
  ".MuiTypography-root": {
    fontSize: "0.9rem",
  },
}))

const Form = () => {
  const { t } = useTranslation()

  const {
    joinButtonDisabled,
    joinError,
    joinStatus,
    meetNumberLength,
    state,
    submit,
    updateState,
    waitingToJoin,
  } = useUsdState()

  const { agreement, meetNumber } = state

  return (
    <FormContainer maxWidth="sm">
      <form>
        <Card>
          <Title title={t("usd.form.card.title")} />
          <CardContent>
            <Group>
              <Box>
                <Label htmlFor="meetNumber">{t("usd.form.field.meetNumber.label")}</Label>
                <Input
                  autoComplete="off"
                  disabled={waitingToJoin}
                  fullWidth
                  id="meetNumber"
                  name="meetNumber"
                  onChange={(event) => updateState(event.target.name, event.target.value)}
                  placeholder={
                    t("usd.form.field.meetNumber.placeholder", { meetNumberLength })
                  }
                  value={meetNumber}
                />
              </Box>
              <Box>
                <JoinButton
                  disabled={joinButtonDisabled}
                  onClick={() => submit()}
                  variant="contained"
                >
                  {t("usd.form.field.joinButton.text")}
                </JoinButton>
              </Box>
            </Group>
            <HelperText
              error={joinError}
            >
              {joinStatus ? t(`usd.form.status.${joinStatus}`) : ""}
            </HelperText>
            <Agreement
              control={
                <Checkbox
                  checked={agreement}
                  disabled={waitingToJoin}
                  name="agreement"
                  onChange={(event) => updateState(event.target.name, !agreement)}
                />
                }
              label={t("usd.form.field.agreement.label")}
            />
          </CardContent>
        </Card>
      </form>
    </FormContainer>
  )
}

export default Form
