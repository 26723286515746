export const AUTH_CHECK = 'AUTH_CHECK';

export const AUTH_CHECKED = 'AUTH_CHECKED';

export const USER_UPDATED = 'USER_UPDATED';

export const TS_UPDATED = 'TS_UPDATED';

export const LOG_IN = 'LOG_IN';

export const LOGGED_IN = 'LOGGED_IN';

export const LOG_IN_FAILED = 'LOG_IN_FAILED';

export const LOG_OUT = 'LOG_OUT';

export const LNG_CHANGED = 'LNG_CHANGED';

export const BACKDROP_TOGGLED = 'BACKDROP_TOGGLED';

export const IS_LICENSES_LOADED_FILE = 'IS_LICENSES_LOADED_FILE';

export const MINT_CHANNEL_CONFERENCE_INFO_UPDATED = "MINT_CHANNEL_CONFERENCE_INFO_UPDATED"
export const MINT_CHANNEL_SEND_CONFERENCE_INFO = "MINT_CHANNEL_SEND_CONFERENCE_INFO"

export const MINT_CHANNEL_SETTINGS = 'MINT_CHANNEL_SETTINGS';

export const MINT_CHANNEL_USER = 'MINT_CHANNEL_USER';

export const MINT_CHANNEL_OPEN = 'MINT_CHANNEL_OPEN';

export const MINT_CHANNEL_CREATED = 'MINT_CHANNEL_CREATED';

export const MINT_CHANNEL_CLOSED = 'MINT_CHANNEL_CLOSED';
