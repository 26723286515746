import { combineReducers } from 'redux';
import { appReducer } from '../app/reducer';
import { mainReducer } from '../main/reducer';
import { meetsReducer } from '../main/content/meets/reducer';
import { meetsFilterReducer } from '../main/content/meets/filter/reducer';
import { archiveReducer } from '../main/content/meets/list/archive/reducer';
import { licensesReducer } from '../main/content/licenses/reducer';
import { usersReducer } from '../main/content/users/reducer';
import { meetReducer } from '../meet/reducer';
import { roomReducer } from '../room/reducer';
import { settingsReducer } from '../main/content/settings/LDAP/reducer';
import { fileManagerReducer } from '../main/content/file_manager/reducer';

export const rootReducer = combineReducers(
    {
        app: appReducer,
        main: mainReducer,
        meets: meetsReducer,
        meetsFilter: meetsFilterReducer,
        archive: archiveReducer,
        licenses: licensesReducer,
        users: usersReducer,
        meet: meetReducer,
        room: roomReducer,
        settings: settingsReducer,
        fileManager: fileManagerReducer
    }
)
