import {
    AUTH_CHECK,
    AUTH_CHECKED,
    LOG_IN,
    LOGGED_IN,
    LNG_CHANGED,
    LOG_OUT,
    MINT_CHANNEL_OPEN,
    MINT_CHANNEL_SEND_CONFERENCE_INFO,
} from './actionTypes';
import { authChecked, logInFailed, mintChannelAddListeners } from './actions';
import i18n from './i18n';
import { appFetch } from './functions';
import { showNotification } from '../main/actions';

export function appMiddleware({ getState, dispatch }) {
    return function (next) {
        return function (action) {
            const state = getState();
            switch (action.type) {
                case AUTH_CHECK: {
                    appFetch(
                        state.app.backEnd + '/api/login/is_logged_in',
                        {
                            method: 'POST',
                            credentials: 'include',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                clientId: state.app.settings.clientId
                            })
                        },
                        (data) => {
                            if (data.status === 'not_auth') {
                                dispatch(
                                    authChecked({
                                        user: { isAuthChecked: true }
                                    })
                                );

                                if (data.settings.title) {
                                    document.getElementsByTagName(
                                        'title'
                                    )[0].innerText = data.settings.title;
                                }
                            }
                        },
                        dispatch,
                        () => {},
                        false
                    );

                    return;
                }
                case AUTH_CHECKED: {
                    // if(i18n.language !== action.payload.settings.lng)
                    //     i18n.changeLanguage(action.payload.settings.lng);
                    break;
                }
                case MINT_CHANNEL_OPEN: {
                    const mintChannel = new window.JitsiMeetJS.MintChannel(
                        state.app.wsUrl,
                        state.app.wsRestoreTimeout
                    );
                    mintChannelAddListeners(mintChannel, dispatch);
                    return;
                }
                case MINT_CHANNEL_SEND_CONFERENCE_INFO: {
                    state.app.mintChannel?.sendConferenceInfo(action.confId)

                    return
                }
                case LOG_IN: {
                    let username = action.payload.username;
                    if (
                        state.app.settings.clientId &&
                        state.app.settings.multiDomain &&
                        state.app.settings.domain !== window.location.hostname
                    )
                        username = `${action.payload.username}@${window.location.hostname}`;
                    appFetch(
                        state.app.backEnd + '/api/login',
                        {
                            credentials: 'include',
                            method: 'POST',
                            headers: {
                                'Content-Type':
                                    'application/x-www-form-urlencoded'
                            },
                            body:
                                'username=' +
                                username +
                                '&password=' +
                                encodeURIComponent(action.payload.password) +
                                '&clientId=' +
                                state.app.settings.clientId
                        },
                        (data) => {
                            if (data.status === 'ok') {
                                return;
                            } else if (data.status === 'auth_failed')
                                dispatch(
                                    logInFailed({ error: 'loginPage.badAuth' })
                                );
                            else if (data.status === 'auth_denied')
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.authDenied'
                                    })
                                );
                            else if (data.status === 'user_not_active')
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.userNotActive'
                                    })
                                );
                            else if (data.status === 'org_exp_date')
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.orgExpDate'
                                    })
                                );
                            else if (data.status === 'ldap_auth_failed')
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.ldap.ldapAuthFailed'
                                    })
                                );
                            else if (
                                data.status === 'contact_the_administrator'
                            )
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.ldap.contactTheAdministrator'
                                    })
                                );
                            else if (data.status === 'user_domain_is_wrong')
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.ldap.userDomainIsWrong'
                                    })
                                );
                            else if (data.status === 'user_domain_not_found')
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.ldap.userDomainNottFound'
                                    })
                                );
                            else if (data.status === 'multi_domain_not_assign')
                                dispatch(
                                    logInFailed({
                                        error: 'loginPage.multi_domain_not_assign'
                                    })
                                );
                            else
                                dispatch(
                                    logInFailed({ error: 'loginPage.error' })
                                );
                        },
                        dispatch,
                        () => {
                            dispatch(logInFailed({ error: 'loginPage.error' }));
                        }
                    );
                    return;
                }
                case LOGGED_IN: {
                    action.payload.user = {
                        ...action.payload.user,
                        isAuthChecked: true
                    };
                    break;
                }
                case LOG_OUT:
                    appFetch(
                        state.app.backEnd + '/api/login/logout',
                        {
                            credentials: 'include'
                        },
                        (data) => {},
                        dispatch,
                        () => {
                            dispatch(
                                showNotification({
                                    severity: 'error',
                                    text: 'navBar.logoutError'
                                })
                            );
                        }
                    );
                    return;
                case LNG_CHANGED:
                    if (state.app.settings.lng !== action.payload.lng)
                        i18n.changeLanguage(action.payload.lng);
                    else return;
                    break;
            }

            return next(action);
        };
    };
}
