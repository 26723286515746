import {
  Card,
  CardContent,
  Container,
  styled,
  Typography,
} from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { ReactComponent as LogoSvg } from "./images/logo.svg"

const HeaderContainer = styled(Container)(() => ({
  paddingTop: "25px",
}))

const Content = styled(CardContent)(() => ({
  alignItems: "center",
  display: "flex",
  gap: "25px",
  justifyContent: "center",
  padding: "25px",
}))

const Logo = styled(LogoSvg)(() => ({
  overflow: "visible",
}))

const Title = styled(Typography)(() => ({
  fontSize: "1.2rem",
  textAlign: "center",
  textTransform: "uppercase",
  whiteSpace: "pre-line",
}))

const Header = () => {
  const { t } = useTranslation()

  return (
    <HeaderContainer maxWidth="md">
      <Card>
        <Content>
          <Logo />
          <Title>{t("usd.header.title")}</Title>
        </Content>
      </Card>
    </HeaderContainer>
  )
}

export default Header
