export const ConferenceStatuses = {
  ERROR: "error",
  FINISHED: "finished",
  LICENSE_USED: "license_used",
  LOADING: "loading",
  NOT_FOUND: "not_found",
  NOT_STARTED: "not_started",
  ORG_PARTICIPANTS_LIMIT: "org_participants_limit",
  PARTICIPANTS_LIMIT: "participants_limit",
  SHOW_LINK: "show_link",
  TOO_EARLY: "too_early",
}
