import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    meetsPageChanged,
    meetsPerPageChanged,
    meetsVisibleRecords
} from '../actions';
import { withTranslation } from 'react-i18next';
import {
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableFooter,
    TableRow,
    TableCell,
    TablePagination
} from '@mui/material';
import Caption from './Caption';
import Filter from '../filter/Filter';
import Items from './Items';
import { isPastMeets, isAllMeets, isCurrentMeets } from '../functions';
import { filterMeets } from '../filter/functions';
import { set } from 'date-fns';


function List({ isRooms, t }) {

    const { _meets, _meetsFilter, _isPastMeets, _currentMeets, _isAllMeets, _user, _isPoolLicense, _ts, _limitPerConf, _meetsVisibleRecords, _uslugirtModeEnabled } = useSelector(state => {

        return {
            _meets: state.meets,
            _meetsFilter: state.meetsFilter,
            _isPastMeets: isPastMeets(state.meets.filterCategory),
            _currentMeets: isCurrentMeets(state.meets.filter),
            _isAllMeets: isAllMeets(state.meets.filter),
            _user: state.app.user,
            _isPoolLicense: state.app.settings.isPoolLicense,
            _ts: state.app.settings.ts,
            _limitPerConf: state.app.settings.limitPerConf,
            _meetsVisibleRecords: state.meets.meetsVisibleRecords,
            _uslugirtModeEnabled: state.app.settings.uslugirtModeEnabled
        }
    })

    const { page, meetsPerPage } = _meets;
    const meetsList = filterMeets(_meets.list, _meetsFilter, isRooms, _isPastMeets, _isAllMeets, _user.isAdmin);
    const dispatch = useDispatch();

    function limitParticipants() {
        return !_currentMeets && !_isPastMeets ? null :
            <TableCell align="center" className="peoples">
                {_isPastMeets ?
                    t('meetsList.peoplesDescriptionLastLimitPerConf')
                    : _currentMeets ? t('meetsList.peoplesCurrentDescriptionLimitPerConf') :
                        null
                }

            </TableCell>

    }
    function allParticipants() {
        {
            return <TableCell align="center" className="peoples">
                {_isPastMeets ?
                    t('meetsList.peoplesDescriptionLast')
                    : _currentMeets ? t('meetsList.peoplesCurrentDescription') :
                        t('meetsList.peoplesDescription')
                }

            </TableCell>
        }
    }
    const numberRecords = (to, count) => {
        return _meetsVisibleRecords && meetsList.length !== 0 ? `${t('pagination.visibleRecords')}: ${to}/${count}` : `${t('pagination.visibleRecords')}: ${count}`
    }
    return (
        <Box className="meetsList">
            <Caption meets={_meets} isRooms={isRooms} />
            <Filter isRooms={isRooms} />
            <TableContainer component={Paper}>
                <Table aria-label="meetsTable">
                    <TableHead>
                        <TableRow>
                            {!isRooms ?
                                <>
                                    <TableCell align="center" className="datetime">{t('meetsList.datetime')}</TableCell>
                                    <TableCell align="center" className="duration">{t('meetsList.duration')}</TableCell>
                                </>
                                :
                                <TableCell align="center" className="roomId">{t('meetsList.roomId')}</TableCell>
                            }

                            {

                                _isPoolLicense && !_limitPerConf ?
                                    limitParticipants() :
                                    allParticipants()

                            }



                            <TableCell align="center" className="description">
                                {
                                    !isRooms
                                        ?
                                        t('meetsList.description')
                                        :
                                        t('meetsList.roomDescription')
                                }
                            </TableCell>
                            <TableCell align="center" className="options">
                                {_uslugirtModeEnabled && !_isPastMeets
                                    ? t('meetsList.number')
                                    : t('meetsList.options')}
                            </TableCell>
                            <TableCell align="right" className="actions"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <Items
                            isRooms={isRooms}
                            isPastMeets={_isPastMeets}
                            meets={_meets}
                            meetsList={meetsList}
                            page={page}
                            meetsPerPage={meetsPerPage}
                            user={_user}
                            isPoolLicense={_isPoolLicense}
                            ts={_ts}
                            limitPerConf={_limitPerConf}
                            currentMeets={_currentMeets}
                            uslugirtModeEnabled={_uslugirtModeEnabled}
                        />
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                className='numberRecords'
                                sx={{
                                    '.MuiTablePagination-actions, .MuiTablePagination-spacer': {
                                        display: 'none',

                                    },

                                    '.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
                                        'padding': 0
                                    }
                                }}
                                colSpan={2}
                                count={meetsList.length}
                                page={page}
                                rowsPerPage={meetsPerPage}
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={
                                    pagination => (
                                        numberRecords(pagination.to, pagination.count)
                                    )

                                }

                                onPageChange={(event, nextPage) => {
                                    dispatch(meetsPageChanged({ page: nextPage }));
                                }}

                            />

                            <TablePagination
                                labelRowsPerPage={t('pagination.rowsPerPage')}
                                labelDisplayedRows={
                                    pagination => (
                                        `${t('pagination.page')} ${pagination.page + 1}`
                                    )

                                }
                                backIconButtonProps={{ title: t('pagination.backward') }}
                                nextIconButtonProps={{ title: t('pagination.forward') }}
                                colSpan={!isRooms ? 6 : 5}
                                count={meetsList.length}
                                page={page}
                                onPageChange={(event, nextPage) => {
                                    dispatch(meetsPageChanged({ page: nextPage }));
                                    dispatch(meetsVisibleRecords());
                                }}
                                rowsPerPage={meetsPerPage}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onRowsPerPageChange={
                                    (event) => {
                                        dispatch(meetsPerPageChanged({ meetsPerPage: parseInt(event.target.value, 10), page: 0 }))
                                        dispatch(meetsVisibleRecords());

                                    }
                                }
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )

}


export default (withTranslation()(List));
