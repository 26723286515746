import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { mintChannelSendConferenceInfo } from "../app/actions"
import { ConferenceStatuses } from "./constants"

/**
 * Loads conference api js.
 * @returns {object|null} The conference server host on js loaded or null.
 */
export const useUsdReadyState = () => {
  const [jsLoaded, setJsLoaded] = useState(false)

  const { _externalApi, _locationHost, _meetNumberLength } = useSelector(({ app }) => {
    return {
      _externalApi: app.externalApi,
      _locationHost: app.locationHost,
      _meetNumberLength: app.settings.meetNumberLength,
    }
  })

  useEffect(() => {
    const conferenceApi = document.createElement("script")
    conferenceApi.src = _externalApi
    document.body.appendChild(conferenceApi)
    conferenceApi.onload = () => {
      setJsLoaded(true)
    }
  }, [_externalApi])

  return {
    conferenceServer: jsLoaded && _meetNumberLength ? _locationHost : null
  }
}

/**
 * Implements the state of the /usd form.
 * @returns {object} The object with props and methods.
 */
export const useUsdState = () => {
  const dispatch = useDispatch()

  /**
   * Stores the form fields values.
   */
  const [state, setState] = useState({
    agreement: false,
    meetNumber: "",
  })

  /**
   * Stores the join button clicked state.
   * Resets on meetNumber field was changed.
   */
  const [isSubmited, setSubmited] = useState(false)

  const {
    _confId,
    _joinStatus,
    _joinError,
    _meetNumberLength,
    _urlBase,
    _uslugirtAuthUrl,
    _uslugirtSign,
    _waitingToJoin,
  } = useSelector(({ app }) => {
    const {
      ERROR,
      FINISHED,
      LOADING,
      NOT_FOUND,
      NOT_STARTED,
      ORG_PARTICIPANTS_LIMIT,
      PARTICIPANTS_LIMIT,
      SHOW_LINK,
      TOO_EARLY,
    } = ConferenceStatuses

    const _joinStatus = isSubmited ? app.conferenceInfo.status : undefined

    const _joinError = [
      ERROR,
      FINISHED,
      NOT_FOUND,
      ORG_PARTICIPANTS_LIMIT,
      PARTICIPANTS_LIMIT,
      TOO_EARLY,
    ].includes(_joinStatus)

    const _waitingToJoin = [
      FINISHED,
      LOADING,
      NOT_STARTED,
      ORG_PARTICIPANTS_LIMIT,
      PARTICIPANTS_LIMIT,
      SHOW_LINK,
      TOO_EARLY,
    ].includes(_joinStatus)

    return {
      _confId: app.conferenceInfo.confId,
      _joinError,
      _joinStatus,
      _meetNumberLength: app.settings.meetNumberLength,
      _urlBase: app.urlBase,
      _uslugirtAuthUrl: app.settings.uslugirtAuthUrl,
      _uslugirtSign: app.conferenceInfo.uslugirtSign,
      _waitingToJoin,
    }
  })

  /**
   * If conferenceInfo.status changed to the SHOW_LINK need do redirect to the auth page.
   */
  useEffect(() => {
    if (_joinStatus === ConferenceStatuses.SHOW_LINK && _confId && _uslugirtSign) {
      const successUrl = `${_urlBase}/usd/${_confId}`
      const errorUrl = `${_urlBase}/usd`

      window.location.href = `${_uslugirtAuthUrl}?success_url=${successUrl}&error_url=${errorUrl}&sign=${_uslugirtSign}`
    }
  }, [_confId, _joinStatus, _urlBase, _uslugirtAuthUrl, _uslugirtSign])

  /**
   * Is allowed to click on JoinButton?
   */
  const joinButtonDisabled = !(state.agreement && state.meetNumber.length === _meetNumberLength) || _waitingToJoin

  /**
   * Updates the form fields state.
   * @param {string} name - The form field name.
   * @param {any} value - The form field value.
   */
  const updateState = (name, value) => {
    let newValue

    switch(name) {
    case "meetNumber":
      newValue = value.replace(/[^\d]/g, "")

      if (newValue.length > _meetNumberLength) {
        newValue =  newValue.slice(0, _meetNumberLength)
      }

      setSubmited(false)

      break

    default:
      newValue = value
    }

    setState({ ...state, [name]: newValue })
  }

  /**
   * Sends the conference info message via MintChannel for joining to the conference.
   */
  const submit = () => {
    dispatch(mintChannelSendConferenceInfo(state.meetNumber))

    setSubmited(true)
  }

  return {
    joinButtonDisabled,
    joinStatus: _joinStatus,
    joinError: _joinError,
    meetNumberLength: _meetNumberLength,
    state,
    submit,
    updateState,
    waitingToJoin: _waitingToJoin,
  }
}
