import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function Iframe({ info, webinar, displayName, locationHost, meetId }) {
    const { _addIAmHidden, _joinHiddenEnabled, _urlBase } = useSelector((state) => {
        return {
            _addIAmHidden: state.meet.addIAmHidden,
            _joinHiddenEnabled: state.meet.info.joinHiddenEnabled,
            _urlBase: state.app.urlBase,
        };
    });

    useEffect(() => {
        document.getElementById('root').style.overflow = 'hidden';

        const options = {
            parentNode: document.querySelector('#iframe'),
            server: locationHost,
            meetId,
            configOverwrite: {
                parentOrigin: _urlBase,
            }
        };

        if (webinar) {
            options.userName = info.webinarReg ? info.displayName : displayName;
            options.configOverwrite.webinarMode = true;
        }

        if (info.webinarFormFields) {
            options.configOverwrite.disablePolls = true;
            options.configOverwrite.quickHideMode = true;
        }

        if (info.uslugirtToken) {
            options.configOverwrite.uslugirtToken = info.uslugirtToken;
            options.userName = displayName || 'Участник';
        }

        if (info.jwt) {
            options.jwt = info.jwt;
        }
        if (_joinHiddenEnabled && _addIAmHidden) {
            options.configOverwrite.iAmHidden = true;
        }

        const api = new window.ConferenceAPI(options);

        api.addEventListener('participantRoleChanged', (event) => {
            if (event.role === 'moderator') {
                if (info.lobby) {
                    api.executeCommand('toggleLobby', true);
                }
                if (info.autoLobby && info.peoples) {
                    api.executeCommand('toggleLobby', true, info.peoples);
                }
                if (info.pass) {
                    api.executeCommand('password', info.pass);
                }
            }
        });
    }, []);

    return <div id="iframe"></div>;
}

export default Iframe;
