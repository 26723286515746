import {
    AUTH_CHECK,
    USER_UPDATED,
    AUTH_CHECKED,
    TS_UPDATED,
    LOG_IN,
    LOGGED_IN,
    LOG_IN_FAILED,
    LOG_OUT,
    LNG_CHANGED,
    BACKDROP_TOGGLED,
    IS_LICENSES_LOADED_FILE,
    MINT_CHANNEL_CONFERENCE_INFO_UPDATED,
    MINT_CHANNEL_SEND_CONFERENCE_INFO,
    MINT_CHANNEL_SETTINGS,
    MINT_CHANNEL_USER,
    MINT_CHANNEL_CREATED,
    MINT_CHANNEL_OPEN,
    MINT_CHANNEL_CLOSED
} from './actionTypes';

export function authCheck() {
    return {
        type: AUTH_CHECK
    };
}

export function userUpdated(payload) {
    return {
        type: USER_UPDATED,
        payload
    };
}

export function authChecked(payload) {
    return {
        type: AUTH_CHECKED,
        payload
    };
}

export function tsUpdated() {
    return {
        type: TS_UPDATED
    };
}

export function logIn(payload) {
    return {
        type: LOG_IN,
        payload
    };
}

export function loggedIn() {
    return {
        type: LOGGED_IN
    };
}

export function logInFailed(payload) {
    return {
        type: LOG_IN_FAILED,
        payload
    };
}

export function logOut() {
    return {
        type: LOG_OUT
    };
}

export function lngChanged(payload) {
    return {
        type: LNG_CHANGED,
        payload
    };
}

export function backdropToggled(enabled = false) {
    return {
        type: BACKDROP_TOGGLED,
        enabled
    };
}

export function isLicensesLoadedFile() {
    return {
        type: IS_LICENSES_LOADED_FILE
    };
}

export const mintChannelConferenceInfoUpdated = (conferenceInfo) => ({
  type: MINT_CHANNEL_CONFERENCE_INFO_UPDATED,
  conferenceInfo,
})

export const mintChannelSendConferenceInfo = (confId) => ({
  type: MINT_CHANNEL_SEND_CONFERENCE_INFO,
  confId,
})

export function mintChannelSettings(payload) {
    return {
        type: MINT_CHANNEL_SETTINGS,
        payload
    };
}

export function mintChannelUser(payload) {
    return {
        type: MINT_CHANNEL_USER,
        payload
    };
}

export const mintChannelOpen = (payload) => {
    return {
        type: MINT_CHANNEL_OPEN,
        payload
    };
};

export const mintChannelCreated = (payload) => {
    return {
        type: MINT_CHANNEL_CREATED,
        payload
    };
};

export const mintChannelClosed = () => {
    return {
        type: MINT_CHANNEL_CLOSED
    };
};

export const mintChannelAddListeners = (mintChannel, dispatch) => {
    const {
        MINT_CHANNEL_OPENED,
        MINT_CHANNEL_CONFERENCE_INFO_UPDATED,
        MINT_CHANNEL_SETTINGS_UPDATED,
        MINT_CHANNEL_USER_UPDATED,
        MINT_CHANNEL_CLOSED
    } = window.JitsiMeetJS.events.mintChannel;
    mintChannel.on(MINT_CHANNEL_OPENED, () => {
        dispatch(mintChannelCreated(mintChannel));
    });

    mintChannel.on(MINT_CHANNEL_CONFERENCE_INFO_UPDATED, (conferenceInfo) => {
      dispatch(mintChannelConferenceInfoUpdated(conferenceInfo))
    })

    mintChannel.on(MINT_CHANNEL_SETTINGS_UPDATED, (message) => {
        dispatch(mintChannelSettings(message));
    });
    mintChannel.on(MINT_CHANNEL_USER_UPDATED, (message) => {
        dispatch(mintChannelUser(message));
    });
    mintChannel.on(MINT_CHANNEL_CLOSED, () => {
        dispatch(mintChannelClosed());
        dispatch(mintChannelOpen());
    });
};
