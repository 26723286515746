import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    TableRow,
    TableCell
} from '@mui/material';
import { prettyDateTime } from '../../../../PrettyDateTime';
import { prettyDuration } from '../../../../PrettyDuration';
import Options from './Options';
import Actions from './actions/Actions';
import { DEFAULT_NAME, USER_DEFAULT_ADMIN_NAME } from '../../../../const';

function Items({ isRooms, isPastMeets, meets, meetsList, page, meetsPerPage, user, isPoolLicense, ts, t, limitPerConf, currentMeets, uslugirtModeEnabled }) {
    function periodicDate(meet) {
        let days = '';
        for (let dow in meet.daysOfWeek)
            if (meet.daysOfWeek[dow])
                days += t('meetForm.daysOfWeek.' + dow) + ' ';

        return days + ' - ' + prettyDateTime(meet.date).split(' ')[1];
    }

    if (meetsList.length > 0) {
        return meetsList.slice(page * meetsPerPage, page * meetsPerPage + meetsPerPage)
            .map(
                meet => {
                    const date = meets.filterCategory === 'future' && meet.periodic
                        ?
                        periodicDate(meet)
                        :
                        prettyDateTime(meet.date);
                    const duration = prettyDuration(meet.duration);
                    const currentIndex = meets.current.indexOf(meet._id);
                    const isCurrent = currentIndex > -1;
                    const currentConference = isCurrent ? meets.currentConferences[currentIndex] : null;
                    const noOrExpiredLicense =
                        !isPoolLicense
                        && !isPastMeets
                        && (!meet.license || meet.license.endTime * 1000 < ts);

                    return (
                        <TableRow key={meet._id} className={noOrExpiredLicense ? 'noOrExpiredLicense' : ''}>
                            {!isRooms ?
                                <>
                                    <TableCell align="center" className="datetime">
                                        {isPastMeets ?
                                            meet.startTime ?
                                                prettyDateTime(meet.startTime)
                                                :
                                                date
                                            : date
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            Boolean(meet.duration) ?
                                                duration.hours
                                                + ' '
                                                + t('meetForm.duration.hours')
                                                + ' '
                                                + duration.minutes
                                                + ' '
                                                + t('meetForm.duration.minutes')
                                                :
                                                '–'
                                        }
                                    </TableCell>
                                </>
                                :
                                <TableCell align="center">
                                    {meet.roomId}
                                </TableCell>
                            }


                            {isPoolLicense && !limitPerConf ?
                                !currentMeets && !isPastMeets ? null :
                                    <TableCell align="center">
                                        {isCurrent ? `${currentConference.participants}` : null}
                                        {isPastMeets ? `${meet.maxParticipantsCount}` : null}

                                    </TableCell>

                                :
                                <TableCell align="center">
                                    {isCurrent ? `${currentConference.participants} / ${meet.peoples}` : null}
                                    {isPastMeets ? meet.peoples === 0 ? `${meet.maxParticipantsCount} / ${meet.maxParticipantsCount}` : `${meet.maxParticipantsCount || meet.peoples} / ${meet.peoples} ` : null}
                                    {!isCurrent && !isPastMeets ? `${meet.peoples}` : null}

                                </TableCell>
                            }
                            <TableCell align="center">
                                {
                                    (user.role === USER_DEFAULT_ADMIN_NAME && user.org?.name === DEFAULT_NAME) ?
                                        meet.org
                                            && meet.org.name
                                            ?
                                            <p className="owner">{meet.org.name} (
                                                {meet.user && meet.user.name ? meet.user.name : t('meetsList.ownerUserDeleted')}
                                                )
                                            </p>
                                            :
                                            null
                                        :
                                        <p className="owner">
                                            {meet.user && meet.user.name ? meet.user.name : t('meetsList.ownerUserDeleted')}
                                        </p>

                                }
                                {meet.description}
                            </TableCell>

                            <TableCell align="center" className="options">
                                {noOrExpiredLicense ?
                                    <p>
                                        {t('meetsList.noOrExpiredLicense')}
                                    </p>
                                    : uslugirtModeEnabled && !isPastMeets
                                        ? meet.number
                                        : <Options meet={meet} periodicDate={periodicDate} />
                                }
                            </TableCell>
                            <TableCell align="right">
                                <Actions meet={meet} date={date} isCurrent={isCurrent} />
                            </TableCell>
                        </TableRow >
                    )
                }
            )
    }

    return (
        <TableRow>
            <TableCell colSpan={!isRooms ? 6 : 5} className="empty"> {t('meetsList.empty')}</TableCell>
        </TableRow>
    )

}

export default withTranslation()(Items);
